<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Classic Diplomas SEO
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Select  Diplomas"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules="required"
              >
           
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.long_course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val"
                  @input="getContent(addCourseForm.long_course_id)"
                >
                  >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
              rules="required"
            >

              <b-form-tags v-model="addCourseForm.keyword" />

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="isAdd"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              add
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              update
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="width: 100%">
      <seo-classic-list ref="table" />
    </b-row>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BFormTags,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import SeoClassicList from './SeoClassicList.vue'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const isAdd = ref(true)
    const coursesList = ref([])
    const categoriesList = ref([])
    const table = ref()
    const addCourseForm = reactive({
      long_course_id: '',
      category: '',
      title: '',
      description: '',
      keyword: '',

    })
    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })
    const getCoursesByCategory = id => {
      
      addCourseForm.long_course_id = ''
      addCourseForm.title = ''
      addCourseForm.description = ''
      addCourseForm.keyword = ''
      coursesList.value = []
      getCourse.value = true
      store.dispatch('longcourses/getCoursesByCategoryClassical', {

        "filter[category_id]": id,

      })
        .then(response => {
            console.log("response",response.data)
          coursesList.value = response.data
        })
    }
    const form = ref()
    const getCourse = ref(false)

    const getContent = item => {
      const { id } = item

      store.dispatch('longSeo/GetSeoCourse', { id }).then(response => {
        { console.log(response)
          if (response.data.data.id) {
            isAdd.value = false
            // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
            addCourseForm.keyword = response?.data.data.keywords.split(',')
            addCourseForm.title = response?.data.data.title
            addCourseForm.description = response?.data.data.description
          } else {
            isAdd.value = true
          }
        }
      })
    }
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('long_course_id', addCourseForm.long_course_id.id)
          formData.append('online', 0)
          formData.append('title', addCourseForm.title)
          formData.append('name', addCourseForm.name)
          formData.append('description', addCourseForm.description)
          formData.append('keywords', addCourseForm.keyword)

          store
            .dispatch('longSeo/addSeoCourseClassic', formData)
            .then(response => {
              addCourseForm.long_course_id = ''
              addCourseForm.category = ''

              addCourseForm.title = ''
              addCourseForm.description = ''
              addCourseForm.keyword = ''
              table.value.GetAllCourses()
              form.value.reset()
              if (isAdd.value == true) {
                Vue.swal({
                  title: ' Seo Classic Diplomas Added ',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                Vue.swal({
                  title: ' Seo Classic Diplomas Updated ',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                isAdd.value = true
              }
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'ID' },
      { key: 'name', label: ' Course Name' },
      { label: 'Title', key: 'link_id' },
      { key: 'name', label: 'Descripation' },
      { label: 'KeyWords', key: 'Date' },

      { key: 'actions' },
    ]
    return {
      isAdd,
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      categoriesList,
      table,
      form,
      getCoursesByCategory,
      getContent,
      save,
      required,
      getCourse,
      email,
    }
  },

  components: {
    BCard,

    SeoClassicList,
    ValidationProvider,
    ValidationObserver,

    BForm,

    BFormTags,

    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>

  .list-tabledata {
    margin: 16px;
}

  .mt-50.col-12 {
    margin: 12px 0px;
}

</style>
